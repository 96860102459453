import React from 'react';
import './Industry.css';
import {Container, Row, Col} from 'react-bootstrap';

export default class Industry extends React.Component{
  render() {
  return (
    <Container>
        <Row className='industry-info'>
          <Col>
            <h1 className='titles color'> 2024 ARC WEPTAC Industry Nights </h1>
            <p className='text mt-4'> For information on how to participate in the October 21-22, 2024 ARC WEPTAC Industry Nights, please contact the host location, the Hilton DoubleTree-Tucson Airport hotel (520-225-0810); they coordinate 100% of the event. </p>
            <p className='text mt-4'> See <a id="industryVendors" href="/docs/Industry Nights Exhibitor Map.pdf" className='text'> linked document </a> for the map of the 2024 Industry Night exhibitor locations.</p>
          </Col>
        </Row>
    </Container>
  );
}
}
