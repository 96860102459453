import React from 'react';
import './Notice.css';

function Notice() {
  return (
    <div>
      <p className='notice-text'>Tucson, AZ <br/> <font color='red'>
      Oct 21 - 25, 2024
      <br/><font color='blue'><i> REGISTRATION IS CLOSED <br/></i></font>
      </font></p>
    </div>
  );
}

export default Notice;
